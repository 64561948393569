<template>
  <mobile-screen
    :header="true"
    screen-class="occupancy-screen gray-bg icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="occupancy-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("search.filters.filters") }}
        </div>
        <template v-slot:right>
          <button @click="toggleSearch()">
            <icon icon="#cx-hea1-search" />
          </button>
        </template>
      </top-header-menu-wrapper>
      <search
        v-if="search"
        @changedInput="modifySearchQuery"
        @clear="clearSearchQuery"
        @startSearch="apply"
      ></search>
    </template>
    <!-- Resource types selection -->
    <ul
      v-if="
        finderData &&
          finderData.resource_types &&
          finderData.resource_types.length
      "
      class="clebex-item-section overflow-hidden"
    >
      <li
        class="clebex-item-section-item"
        :class="collapse['resourceTypes'] ? 'collapsed' : 'expanded'"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label larger-label">
              {{ displayLabelName("occupancy.finder.resource-types") }}
            </label>
          </div>
          <span
            class="follow-up-icons cursor-pointer"
            @click="
              collapse['resourceTypes']
                ? (collapse['resourceTypes'] = false)
                : (collapse['resourceTypes'] = true)
            "
          >
            <span class="follow-up-icon-item collapsable"
              ><icon icon="#cx-app1-arrow-right-12x12" width="12" height="12"
            /></span>
          </span>
        </div>
      </li>
      <section
        :class="collapse['resourceTypes'] ? 'collapsed' : 'expanded five'"
      >
        <li
          v-for="resourceType in finderData.resource_types"
          :key="resourceType.id"
          class="clebex-item-section-item no-bottom-border light no-separator"
        >
          <div
            @click="selectResourceType(resourceType.id)"
            class="clebex-item-content-wrapper cursor-pointer"
          >
            <div class="clebex-section-input">
              <label class="clebex-section-input-label cursor-pointer"
                >{{ resourceType.name }}
              </label>
            </div>
            <span
              v-if="selectedResourceTypeIdsLocal.includes(resourceType.id)"
              class="follow-up-icons"
            >
              <span class="follow-up-icon-item">
                <div class="svg-icon">
                  <svg
                    width="12"
                    height="12"
                    style="width: 12px; height: 12px;"
                  >
                    <use xlink:href="#cx-app1-checkmark"></use>
                  </svg>
                </div>
              </span>
            </span>
          </div>
        </li>
      </section>
    </ul>
    <!-- Resources selection -->
    <ul
      v-if="finderData && finderData.resources && finderData.resources.length"
      class="clebex-item-section overflow-hidden"
    >
      <li
        class="clebex-item-section-item"
        :class="collapse['resources'] ? 'collapsed' : 'expanded'"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label larger-label">
              {{ displayLabelName("occupancy.finder.resources") }}
            </label>
          </div>
          <span
            class="follow-up-icons cursor-pointer"
            @click="
              collapse['resources']
                ? (collapse['resources'] = false)
                : (collapse['resources'] = true)
            "
          >
            <span class="follow-up-icon-item collapsable"
              ><icon icon="#cx-app1-arrow-right-12x12" width="12" height="12"
            /></span>
          </span>
        </div>
      </li>
      <section :class="collapse['resources'] ? 'collapsed' : 'expanded ten'">
        <li
          v-for="resource in finderData.resources"
          :key="resource.id"
          class="clebex-item-section-item no-bottom-border light no-separator"
        >
          <div
            @click="selectResource(resource.id)"
            class="clebex-item-content-wrapper cursor-pointer"
          >
            <div class="clebex-section-input">
              <label class="clebex-section-input-label cursor-pointer"
                >{{ resource.name }}
              </label>
            </div>
            <span
              v-if="resource.id === selectedResourceId"
              class="follow-up-icons"
            >
              <span class="follow-up-icon-item">
                <div class="svg-icon">
                  <svg
                    width="12"
                    height="12"
                    style="width: 12px; height: 12px;"
                  >
                    <use xlink:href="#cx-app1-checkmark"></use>
                  </svg>
                </div>
              </span>
            </span>
          </div>
        </li>
      </section>
    </ul>
    <template v-slot:footer>
      <nav class="actions-menu theme-gray">
        <ul class="actions-list">
          <li class="action">
            <button class="action-btn" @click="clearAll">
              {{ displayLabelName("search.filters.clear-all") }}
            </button>
          </li>
          <li v-if="!applied" class="action">
            <button class="action-btn" @click="apply">
              {{ displayLabelName("search.filters.apply") }}
            </button>
          </li>
        </ul>
      </nav>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapActions } from "vuex";

export default {
  name: "OccupancyFinderMapFilters",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    Search: defineAsyncComponent(() => import("@/components/search/Search"))
  },
  data() {
    return {
      search: false,
      collapse: {},
      selectedResourceTypeIdsLocal: [],
      applied: true
    };
  },
  computed: {
    ...mapState("search", ["searchQuery"]),
    ...mapState("occupancy", [
      "finderData",
      "selectedResourceId",
      "finderSelectedLevelId",
      "selectedResourceTypeIdsNum"
    ])
  },
  created() {
    if (!this.finderSelectedLevelId) {
      this.$router.push({ name: this.backLinkName });
    }
    this.getFinderData({ types: null, filter: "" });
  },
  methods: {
    ...mapActions("occupancy", ["getFinderData"]),
    modifySearchQuery(searchQuery) {
      this.$store.commit("search/setSearchQuery", searchQuery, {
        root: true
      });
    },
    clearSearchQuery() {
      this.$store.commit("search/setSearchQuery", "", {
        root: true
      });
      this.apply();
    },
    clearAll() {
      this.selectedResourceTypeIdsLocal = [];
      this.$store.commit("occupancy/setSelectedResourceId", null, {
        root: true
      });
      this.applied = false;
    },
    apply() {
      this.$store.commit(
        "occupancy/setSelectedResourceTypeIds",
        this.selectedResourceTypeIdsLocal,
        {
          root: true
        }
      );
      this.$store.commit(
        "occupancy/setSelectedResourceTypeIdsNum",
        this.selectedResourceTypeIdsNum + 1,
        {
          root: true
        }
      );
      this.getFinderData({
        types: this.selectedResourceTypeIdsLocal,
        filter: this.searchQuery
      }).then(() => {
        this.applied = true;
      });
    },
    selectResourceType(id) {
      this.applied = false;
      if (this.selectedResourceTypeIdsLocal.includes(id)) {
        this.selectedResourceTypeIdsLocal = this.selectedResourceTypeIdsLocal.filter(
          el => el !== id
        );
      } else {
        this.selectedResourceTypeIdsLocal.push(id);
      }
    },
    selectResource(id) {
      let selectedResourceId = this.selectedResourceId === id ? null : id;
      this.$store.commit(
        "occupancy/setSelectedResourceId",
        selectedResourceId,
        {
          root: true
        }
      );
    },
    toggleSearch() {
      if (this.search) {
        this.clearSearchQuery();
        this.search = false;
      } else {
        this.search = true;
      }
    }
  },
  watch: {},
  props: {
    backLinkName: {
      type: String,
      default: "r_occupancy-dashboard"
    }
  }
};
</script>
